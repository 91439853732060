import { Theme, Grid, Typography } from '@mui/material'
import { memo } from 'react'
import { observer } from 'mobx-react'
import Tuev from 'components/DigitalAccountCheck/TrustElement/Tuev'
import Ekomi from 'components/DigitalAccountCheck/TrustElement/Ekomi'
import GuenstigerGehtNicht from 'components/DigitalAccountCheck/TrustElement/GuenstigerGehtNicht'
import useEmotionStyles from 'hooks/useEmotionStyles'

const styles = (theme: Theme) => ({
  footerContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    margin: '0 auto',
    maxWidth: '532px'
  },
  parentContainer: {
    padding: theme.spacing(2)
  },
  footerTitle: {
    marginBottom: theme.spacing(2)
  }
})

interface Props {
  isNotAffiliate?: boolean
}

const DDFTrustCards = observer(({ isNotAffiliate }: Props) => {
  const { footerContainer, footerTitle } = useEmotionStyles(styles)

  return (
    <>
      <Grid className={footerContainer}>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" align="center" className={footerTitle}>
            {isNotAffiliate ? 'TÜV geprüft + ' : ''}SCHUFA-neutral
          </Typography>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            {isNotAffiliate && (
              <Grid item>
                <Tuev width="80px" />
              </Grid>
            )}
            <Grid item>
              <GuenstigerGehtNicht />
            </Grid>
            <Grid item>
              <Ekomi />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
})

export default memo(DDFTrustCards)
