import { isTenantSmava } from 'utils/url'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import UnknownConfigError from 'error/unknownConfigError'
import { isSmava } from 'utils/affiliate'

import { LoadedConfig } from '../config.interface'
import RootStore from '../../stores/RootStore'

export const CONFIG_TEST = 'test_config'
export const CONFIG_TEST_URL_PARAMS = 'test_url_params'

export const CONFIG_SECOND_DEBTOR = 'second_debtor'
export const CONFIG_SECOND_DEBTOR_SMAVA = 'second_debtor_smava'
export const CONFIG_DDF = 'ddf'
export const CONFIG_DDF_SEPARATED_DAC = 'ddf_separated_dac'
export const CONFIG_DDF_SEM_HARD_DAC = 'ddf_sem_hard_dac'
export const CONFIG_LEAD_FORM = 'lead_form'
export const CONFIG_MOBILEDE = 'mobilede'
export const CONFIG_DDF_SMAVA = 'ddf_smava'
export const CONFIG_DDF_SMAVA_MARKETING = 'ddf_smava_marketing'
export const CONFIG_DDF_SMAVA_SEPARATED_DAC = 'ddf_smava_separated_dac'
export const CONFIG_DDF_MARKETING = 'ddf_marketing'
export const CONFIG_DDF_SMAVA_RCE_EDIT = 'ddf_smava_rce_edit_page'

export const configMap = {
  [CONFIG_TEST]: () => import('../test-config'),
  [CONFIG_TEST_URL_PARAMS]: () => import('../test-url-params'),
  [CONFIG_SECOND_DEBTOR]: () => import('../second-debtor-config'),
  [CONFIG_SECOND_DEBTOR_SMAVA]: () => import('../second-debtor-config-smava'),
  [CONFIG_DDF]: () => import('../ddf-config'),
  [CONFIG_LEAD_FORM]: () => import('../lead-form-config'),
  [CONFIG_MOBILEDE]: () => import('../mobilede-config'),
  [CONFIG_DDF_SMAVA]: () => import('../ddf-smava-config'),
  [CONFIG_DDF_SMAVA_MARKETING]: () => import('../ddf-smava-marketing-config'),
  [CONFIG_DDF_MARKETING]: () => import('../ddf-marketing-config'),
  [CONFIG_DDF_SEPARATED_DAC]: () => import('../ddf-separated-dac-config'), // used in experiment CAT-2784,
  [CONFIG_DDF_SEM_HARD_DAC]: () => import('../ddf-sem-hard-dac-config'), // used by SEM for Hard DAC
  [CONFIG_DDF_SMAVA_SEPARATED_DAC]: () =>
    import('../ddf-smava-separated-dac-config'), // used in experiment CAT-3323
  [CONFIG_DDF_SMAVA_RCE_EDIT]: () => import('../ddf-smava-rce-edit-page') // used in experiment CAT-3316
}

export type ConfigName = keyof typeof configMap

export function doesConfigExist(name: string) {
  return Object.keys(configMap).indexOf(name) > -1
}

/**
 * We have to do it like this because Webpack needs to know the imports in advance.
 * Otherwise the config file won't be bundled correcly and cannot be found.
 *
 * @param name e.g. `classic-config`
 */
export async function getConfig(name: ConfigName): Promise<LoadedConfig> {
  const configImport = configMap[name]
  if (!configImport) {
    logAndTrackError(new UnknownConfigError(name))

    if (isTenantSmava()) {
      return (await configMap[CONFIG_DDF_SMAVA]()).default
    }

    return (await configMap[CONFIG_DDF]()).default
  }

  return (await configImport()).default
}

export async function getActiveConfig(
  affiliateId: number
): Promise<LoadedConfig> {
  if (isSmava(affiliateId)) {
    return getConfig(CONFIG_DDF_SMAVA)
  }

  return getConfig(CONFIG_DDF)
}

export function getDisplayedPages(rootStore: RootStore) {
  const {
    page: { config }
  } = rootStore

  if (!config.version) {
    return []
  }

  return Object.entries(config.pages).filter(
    ([, options]) => !options.condition || options.condition(rootStore)
  )
}

export const isConfigWithoutSaveButton = (config: string | undefined) =>
  config === CONFIG_DDF_SMAVA_SEPARATED_DAC ||
  config === CONFIG_DDF_SEPARATED_DAC ||
  config === CONFIG_DDF_SMAVA_RCE_EDIT

export const isConfigWithoutInactivityRedirection = (
  config: string | undefined
) => config === CONFIG_DDF_SMAVA_MARKETING

export const isMarketingConfig = (config: string | undefined) =>
  config === CONFIG_DDF_SMAVA_MARKETING || config === CONFIG_DDF_MARKETING

export const isFFGConfigWithAutoRedirectOnDacSuccess = (
  config: string | undefined
) =>
  config === CONFIG_DDF_SEPARATED_DAC ||
  config === CONFIG_DDF_MARKETING ||
  config === CONFIG_DDF_SEM_HARD_DAC

const ddfSmavaConfigs = [
  CONFIG_DDF_SMAVA,
  CONFIG_DDF_SMAVA_MARKETING,
  CONFIG_DDF_SMAVA_SEPARATED_DAC,
  CONFIG_DDF_SMAVA_RCE_EDIT
]

export const isDDFSmavaConfig = (config: string | undefined) =>
  config && ddfSmavaConfigs.includes(config)

export const isSeparatedDacConfig = (config: string | undefined) =>
  config === CONFIG_DDF_SEPARATED_DAC ||
  config === CONFIG_DDF_SMAVA_SEPARATED_DAC ||
  config === CONFIG_DDF_SMAVA_RCE_EDIT
