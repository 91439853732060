import { memo } from 'react'

import CreateFinalAfterInactivity, {
  InactivityType
} from './containers/CreateFinalAfterInactivity'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { getAge } from 'utils/date'
import { determineTimeout, LONG_TIMEOUT } from 'InactivityContainer.helpers'

type InactivityProps = {
  handleSubmit: () => void
}

const InactivityContainer = ({ handleSubmit }: InactivityProps) => {
  const {
    rootStore: {
      page: { config },
      traversal: {
        data: {
          debtors: {
            primary: {
              personal: { birthday }
            }
          }
        }
      },
      experimentData
    }
  } = useRootStore()

  const debtorsAge = getAge(birthday)
  let timeout = determineTimeout(debtorsAge, config.name)

  if (!timeout) {
    return null
  }

  return (
    <>
      <CreateFinalAfterInactivity
        timeout={LONG_TIMEOUT}
        inactivityType={InactivityType.PAYOUT_ACCOUNT_INACTIVITY}
        onNext={handleSubmit}
      />
      <CreateFinalAfterInactivity
        timeout={timeout}
        inactivityType={InactivityType.DAC_INACTIVITY}
        onNext={handleSubmit}
      />
    </>
  )
}

export default memo(InactivityContainer)
