import React, { useEffect, memo, useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import { NavigateFunction } from 'react-router'
import { FintecState } from '@finanzcheck/ti-fts-widget'
import {
  RegisteredEventName,
  broadcastToParent
} from '@finanzcheck/catalyst-pollard'
import { getButtonSizes } from 'components/FormNavigation/getButtonSizes'
import { useSaveButton } from 'hooks/useSaveButton'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { useStores } from 'stores/utils/hooks/useStores'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { Typography } from '@mui/material'
import * as CSSTypes from 'csstype'
import useEmotionStyles from 'hooks/useEmotionStyles'
import LegalHintTexts from 'texts/common/copy/legal'
import { DacWidgetTabEnum } from 'stores/TraversalStore'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { getOptimizelyTrackingAttribute } from 'utils/tracking'
import {
  IBAN_PATH,
  usePayoutAccountValidation
} from 'hooks/usePayoutAccountValidation'
import {
  CONFIG_DDF_SMAVA,
  CONFIG_SECOND_DEBTOR_SMAVA,
  isMarketingConfig,
  isSeparatedDacConfig
} from 'config/utils/config'
import { useField } from 'react-final-form'
import FormNavigationTemplate from 'components/FormNavigation/FormNavigationTemplate'
import { getAge } from 'utils/date'
import { MIDDLE_AGE } from 'constants/components'
import { DACEvent } from 'components/DigitalAccountCheck/helpers'

type Props = {
  onBack?: () => void
  onNext: () => void
  legalContact: string
  navigate: NavigateFunction
  isOfferLabel?: boolean
}

const styles = () => ({
  flexBasisGrid: {
    flexBasis: 'auto',
    marginTop: '5px'
  },
  button: {
    maxHeight: '48px'
  },
  buttonFullHeight: {
    height: '48px',
    '& button': {
      height: '48px'
    }
  },
  buttonTitle: {
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.5rem'
  },
  buttonHint: {
    color: '#fff',
    fontSize: '0.75rem',
    lineHeight: '1.25rem'
  },
  buttonTextContainer: {
    flexDirection: 'column' as CSSTypes.Property.FlexDirection,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const getBackButtonLabel = (isCustomBackButtonLabelInUse: boolean) => {
  if (isCustomBackButtonLabelInUse) {
    return 'Zur letzten Seite'
  }

  return 'Zurück'
}

const FormNavigation = observer(
  ({ onBack, onNext, isOfferLabel, navigate }: Props) => {
    const {
      rootStore: {
        isInIframe,
        urlParams: { backUrl },
        traversal: {
          data: {
            debtors: {
              primary: {
                personal: { birthday }
              }
            }
          }
        },
        experimentData
      }
    } = useRootStore()
    const isPayoutAccountReady = usePayoutAccountValidation(true)
    const [
      isIbanPresentOnPayoutAccountPage,
      setIsIbanPresentOnPayoutAccountPage
    ] = useState(false)

    const {
      page: {
        isFirstDisplayedPage,
        getCurrentIndex,
        isLastDisplayedPage,
        config
      },
      navigation: { currentPageInSession },
      traversal: { data, dacWidgetTab, dacWidgetState, isDacSkipped }
    } = useStores()

    const isLastPage = isLastDisplayedPage(currentPageInSession)

    const ibanField = useField(IBAN_PATH, {})

    const { isSaveButtonVisible } = useSaveButton({
      onSuccess: () => {},
      onError: () => {}
    })

    useEffect(() => {
      setIsIbanPresentOnPayoutAccountPage(
        currentPageInSession === 'payoutAccount' &&
          ibanField.input.value &&
          dacWidgetState === 'initial'
      )
    }, [data, currentPageInSession, dacWidgetState])

    const currentIndex = getCurrentIndex()

    const trackWithOrigin = (event: AmplitudeEvent, payload = {}) => {
      trackInAmplitude(event, { origin: 'taurine', ...payload })
    }

    const onNextWithTracking = useCallback(() => {
      if (dacWidgetTab === DacWidgetTabEnum.SLOW) {
        trackWithOrigin(AmplitudeEvent.OnDacStateChange, {
          state: 'disagreed' as FintecState
        })
        broadcastToParent({
          eventName: RegisteredEventName.tracking,
          data: {
            event: DACEvent.STATE_CHANGE_DISAGREED,
            context: {
              Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
              ...getOptimizelyTrackingAttribute(data)
            }
          }
        })
      }

      onNext()
    }, [onNext, dacWidgetTab, data])

    useEffect(() => {
      if (dacWidgetState === 'success') {
        trackWithOrigin(AmplitudeEvent.OnDACSuccessAutoRedirect)
        onNextWithTracking()
      }
    }, [dacWidgetState])

    const { buttonTitle, buttonHint, buttonTextContainer } =
      useEmotionStyles(styles)

    const isFirstPage = isFirstDisplayedPage(currentPageInSession)

    const debtorsAge = getAge(birthday)

    const getSubmitLabel = () => {
      if (isFirstPage || isOfferLabel) {
        const title =
          isOfferLabel || debtorsAge >= MIDDLE_AGE
            ? 'Weiter zu den Angeboten'
            : 'Kreditvergleich starten'

        const hint = 'kostenlos und unverbindlich'

        return (
          <div className={buttonTextContainer}>
            <Typography className={buttonTitle}>{title}</Typography>
            <Typography className={buttonHint}>{hint}</Typography>
          </div>
        )
      }

      return 'Weiter'
    }

    const isContinueWithoutDacVisible =
      isSeparatedDacConfig(config.name || '') &&
      isLastPage &&
      dacWidgetState !== 'success'

    const isCustomBackButtonLabelInUse =
      isLastPage &&
      isPayoutAccountReady &&
      dacWidgetTab === DacWidgetTabEnum.QUICK &&
      config.name !== CONFIG_DDF_SMAVA

    const backLabel =
      !isInIframe || currentIndex > 0 || backUrl
        ? getBackButtonLabel(isCustomBackButtonLabelInUse)
        : ''

    const isBackButtonHidden =
      !backLabel ||
      isFirstPage ||
      (isSeparatedDacConfig(config.name || '') && isLastPage)

    const getIsContinueButtonHidden = () => {
      if (isSeparatedDacConfig(config.name || '')) {
        return isLastPage && dacWidgetState !== 'success'
      }

      if (config.name === CONFIG_SECOND_DEBTOR_SMAVA) {
        return false
      }

      return (
        isLastPage &&
        isPayoutAccountReady &&
        dacWidgetTab === DacWidgetTabEnum.QUICK &&
        dacWidgetState !== 'success' &&
        !isIbanPresentOnPayoutAccountPage &&
        debtorsAge < MIDDLE_AGE
      )
    }

    const showDacLegalText =
      isLastPage &&
      isPayoutAccountReady &&
      currentPageInSession === 'dac' &&
      dacWidgetTab === DacWidgetTabEnum.QUICK &&
      config.name === CONFIG_DDF_SMAVA

    const isContinueButtonHidden = getIsContinueButtonHidden()

    const { sizeOfContinueButton, sizeOfBackButton, sizeOfSaveButton } =
      getButtonSizes(
        isBackButtonHidden,
        isSaveButtonVisible,
        isContinueButtonHidden
      )

    const onBackHandler = () => {
      if (
        !isMarketingConfig(config.name) &&
        dacWidgetState !== 'initial' &&
        debtorsAge < MIDDLE_AGE
      ) {
        broadcastToParent({
          eventName: RegisteredEventName.tracking,
          data: {
            event: 'final_on_back_button_click',
            context: {
              Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
              ...getOptimizelyTrackingAttribute(data)
            }
          }
        })
        onNext()
        return
      }
      onBack && onBack()
    }

    const continueWithoutDacButtonVariant = isSeparatedDacConfig(
      config.name || ''
    )
      ? 'outlined'
      : 'text'

    return (
      <FormNavigationTemplate
        onBack={onBackHandler}
        onNext={onNext}
        navigate={navigate}
        legalText={LegalHintTexts.smavaLegalHint}
        isContinueWithoutDacVisible={isContinueWithoutDacVisible}
        isBackButtonHidden={isBackButtonHidden}
        isContinueButtonHidden={isContinueButtonHidden}
        sizeOfContinueButton={sizeOfContinueButton}
        sizeOfBackButton={sizeOfBackButton}
        sizeOfSaveButton={sizeOfSaveButton}
        submitLabel={getSubmitLabel()}
        backLabel={backLabel}
        onNextWithTracking={onNextWithTracking}
        trackWithOrigin={trackWithOrigin}
        showPreviousPageError={!isDacSkipped}
        continueWithoutDacButtonVariant={continueWithoutDacButtonVariant}
        showDacLegalText={showDacLegalText}
      />
    )
  }
)

export default memo(FormNavigation)
